import { css } from '@emotion/react'
import { Button } from '@mantine/core'
import React, { memo, useEffect, useState } from 'react'
import { proxy } from 'valtio'
import { apirc } from '~/configs/apirc'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { useDaddy960Trial } from './useDaddy960Tutorial'

/** 記錄客戶按下試用按鈕行為 觸發後續'跳轉'與'試用申請中'的字眼 */
export const redirectStore = proxy({
  redirect: false as boolean,
})

export const Daddy960FreeTrialButton = memo<ReactProps>(function Daddy960FreeTrialButton() {
  const [readyTrial, setReadyTrial] = useState(false)
  const [countdown, setCountdown] = useState(5)

  useEffect(() => {
    const timer = setInterval(() => {
      setReadyTrial(false)
      setCountdown(5)
    }, 5000)
    return () => clearTimeout(timer)
  }, [readyTrial])

  useEffect(() => {
    const clicked = readyTrial === true && countdown > 0
    const count = setTimeout(() => clicked && setCountdown(countdown - 1), 1000)
    return () => clearTimeout(count)
  }, [countdown, readyTrial])

  //點擊送出試用過3秒再重整網頁
  const handleClick = () => {
    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }

  return (
    <>
      {readyTrial === false && (
        <Button
          css={css`
            ${fill_horizontal_all_center}
            width: 260px;
            height: 36px;
            color: #333333;
            background-color: #ffc641;
          `}
          onClick={() => setReadyTrial(true)}
        >
          點擊按鈕開始7天試用
        </Button>
      )}
      {readyTrial === true && (
        <Button
          css={css`
            ${fill_horizontal_all_center};
            width: 260px;
            height: 36px;
            color: #333333;
          `}
          onClick={() => {
            apirc.me.api.postFreeTrial.fetch()
            redirectStore.redirect = true
            handleClick()
          }}
          color='yellow'
        >
          確定啟用? ({countdown}秒後取消)
        </Button>
      )}
    </>
  )
})
